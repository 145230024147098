import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import LogoFooter from "../images/alexandra-prenosil-logo-negative.svg";

const Footer = () => {
  return (
    <>
      <div
        className="bg-secondary"
        style={{ position: "relative", zIndex: 999 }}
      >
        <Container className="py-4">
          <Row>
            <Col>
              <h3 className="text-background text-center">
                Terminvereinbarung per Telefon oder Email:
              </h3>
              <h3 className="text-background text-center">
              <a
                  className="text-background"
                  href="tel:+41 79 329 25 87"
                >
                079 329 25 87</a> |{" "}
                <a
                  className="text-background"
                  href="mailto:praxis@prenosil.ch"
                >
                  praxis@prenosil.ch
                </a>
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <footer
        className="bg-primary"
        style={{ position: "relative", zIndex: 999 }}
      >
        <Container className="py-4">
          <Row>
            <Col
              xs={12}
              md={4}
              className="text-center text-md-start order-1 order-md-0"
            >
              <p className="text-background mb-0">
                Zollikerstrasse 19 <br />
                8702 Zollikon
                <br />
                079 329 25 87
                <br />
                <a
                href="mailto:praxis@prenosil.ch"
                className="text-background"
              >
                praxis@prenosil.ch
              </a>
              </p>
            </Col>
            <Col
              xs={12}
              md={4}
              className="d-flex text-center justify-content-center align-items-center order-0 order-md-1 mb-3"
            >
              <Link to="/" aria-label="Home">
                <LogoFooter className="logo-footer" alt="logo" />
              </Link>
            </Col>

            <Col xs={12} md={4} className="d-none d-md-block order-md-2">
              <div className="text-end">
                <Link
                  className="text-background"
                  activeClassName="active"
                  to="/therapiemethoden"
                >
                  Therapiemethoden
                </Link>
                <br />

                <Link
                  className="text-background"
                  activeClassName="active"
                  to="/preis-und-termine"
                >
                  Preis und Termine
                </Link>
                <br />

                <Link
                  className="text-background"
                  activeClassName="active"
                  to="/ueber-mich"
                >
                  Über mich
                </Link>
                <br />

                <Link
                  className="text-background"
                  activeClassName="active"
                  to="/kontakt"
                >
                  Kontakt
                </Link>
                <br />

                <Link
                  className="text-background"
                  activeClassName="active"
                  to="/links"
                >
                  Links
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <Container className="my-3">
        <Row>
          <Col className="text-center text-md-start">
            <Link to="/impressum">Impressum</Link> |{" "}
            <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
