import React from "react";
import { Link } from "gatsby";
import { Container, Navbar, Nav } from "react-bootstrap";
import Logo from "../images/alexandra-prenosil-logo.svg";

const Header = () => {
  return (
    <>
      <div className="skippy visually-hidden-focusable overflow-hidden">
        <div className="container-xl">
          <a className="d-inline-flex p-2 m-1" href="#content">
            Skip to main content
          </a>
        </div>
      </div>

      <header>
        <Navbar expand="md" variant="light">
          <Container fluid="md" className="justify-content-center flex-wrap">
            <Link
              className="navbar-brand order-md-1 m-0"
              to="/"
              aria-label="Home"
            >
              <Logo className="logo" alt="logo" />
            </Link>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="border-0 p-1"
              style={{ position: "absolute", right: 12, top: 9 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="#3d7451"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                ></path>
              </svg>
            </Navbar.Toggle>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end justify-content-md-center order-md-0 w-100"
            >
              <Nav className="my-4">
                {/* <Link
                  className="nav-link text-center text-md-start"
                  activeClassName="active"
                  to="/leistungen"
                >
                  Leistungen
                </Link> */}
                <Link
                  className="nav-link text-center text-md-start"
                  activeClassName="active"
                  to="/therapiemethoden"
                >
                  Therapiemethoden
                </Link>
                <Link
                  className="nav-link text-center text-md-start"
                  activeClassName="active"
                  to="/preis-und-termine"
                >
                  Preis und Termine
                </Link>
                <Link
                  className="nav-link text-center text-md-start"
                  activeClassName="active"
                  to="/ueber-mich"
                >
                  Über mich
                </Link>
                <Link
                  className="nav-link text-center text-md-start"
                  activeClassName="active"
                  to="/kontakt"
                >
                  Kontakt
                </Link>
                <Link
                  className="nav-link text-center text-md-start"
                  activeClassName="active"
                  to="/links"
                >
                  Links
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
